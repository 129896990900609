import Link from "next/link";
import Image from 'next/image';
import React, { useEffect, useState } from "react";
import { useRouter } from 'next/router'

const Nav = () => {
  const [isMobileNavOpen, setIsMobileNavOpen] = useState(false);
  const { asPath } = useRouter() // check if this is home page or not to show scroller link
  const currentPath = asPath.substring(1).replaceAll("-", "").toLowerCase(); // this is used for the active state
  return (
    <nav className="row navbar-row">
      <section className="navbar navbar-expand-lg col-md-12 d-none d-sm-none d-md-none d-lg-none d-xl-block">
        <div className="navbar-collapse" id="los-nav">
          <Link href="/" legacyBehavior>
            <a className="navbar-brand">
              <img src="/img/logo.png" alt="Line of sight Logo" />
            </a>
          </Link>
          <ul className="navbar-nav ml-auto">
            <li className={`nav-item medium-font execution ${currentPath == 'execution' ? 'active' : ''}`}>
              <Link href="/execution" legacyBehavior>
                <a className={`nav-link`}>Execution</a>
              </Link>
            </li>
            <li className={`nav-item medium-font vistage_chairs ${currentPath == 'becomealosimplementer' ? 'active' : ''}`}>
              <Link href="/become-a-los-implementer" legacyBehavior>
                <a className={`nav-link`}>Become a LOS Implementer</a>
              </Link>
            </li>
            <li className={`nav-item medium-font science_and_data ${currentPath == 'scienceanddata' ? 'active' : ''}`}>
              <Link href="/science-and-data" legacyBehavior>
                <a className="nav-link">Science and Data</a>
              </Link>
            </li>
          
            <li className={`nav-item medium-font application ${currentPath == 'application' ? 'active' : ''}`}>
              <Link href="/application" legacyBehavior>
                <a className="nav-link">Application</a>
              </Link>
            </li>
          
            <li className={`nav-item medium-font find_a_partner ${currentPath == 'findanimplementer' ? 'active' : ''}`}>
              <Link href="/find-an-implementer" legacyBehavior>
                <a className="nav-link">Find An Implementer</a>
              </Link>
            </li>
          
            <li className={`nav-item medium-font blog ${currentPath == 'blog' || currentPath.includes('post/') ? 'active' : ''}`}>
              <Link href="/blog" legacyBehavior>
                <a className="nav-link">Blog</a>
              </Link>
            </li>
          
            <li className={`nav-item medium-font about ${currentPath == 'aboutus' ? 'active' : ''}`}>
              <Link href="/about-us" legacyBehavior>
                <a className="nav-link">About</a>
              </Link>
            </li>
            
            <li className="login-link">
              <a className="nav-link text-center" href="http://lineofsight.thelineofsight.com/users/sign_in" target="_BLANK">
                <img src="/img/nav-icon-login.svg" alt="User Icon"/>
                <br />
                <span>Client Login</span>
              </a>
            </li>
          </ul>
        </div>
      </section>
      <section className="col-12 d-block d-sm-block d-md-block d-lg-block d-xl-none">
        <div className="col-6 mobile-brand-container">
          <Link href="/" legacyBehavior>
            <a className="navbar-brand mobile-navbar-brand">
              <img src="/img/logo.png" alt="Line of sight Logo" />
            </a>
          </Link>
        </div>
        <div id="sidebar-toggle" onClick={() => setIsMobileNavOpen((prev) => !prev)} className="d-block d-sm-block d-md-block d-lg-block d-xl-none" data-ol-has-click-handler="">
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className={isMobileNavOpen ? "showMenuNav" : "hideMenuNav"}>
          <div className="mobile-nav" id="nav-right">
            <ul className="mobile-nav-items book-font">
              <li className="nav-item medium-font vistage_chairs">
                <Link href="/become-a-los-implementer" legacyBehavior>
                  <a className="nav-link">Become a LOS Implementer</a>
                </Link>
              </li>
              <li className="nav-item medium-font science_and_data">
                <Link href="/science-and-data" legacyBehavior>
                  <a className="nav-link">Science and Data</a>
                </Link>
              </li>
              <li className="nav-item medium-font application">
                <Link href="/application" legacyBehavior>
                  <a className="nav-link">Application</a>
                </Link>
              </li>
              <li className="nav-item medium-font find_a_partner">
                <Link href="/find-a-partner" legacyBehavior>
                  <a className="nav-link">Find An Implementer</a>
                </Link>
              </li>
              <li className="nav-item medium-font blog">
                <Link href="/blog" legacyBehavior>
                  <a className="nav-link">Blog</a>
                </Link>
              </li>
              <li className="nav-item medium-font about">
                <Link href="/about-us" legacyBehavior>
                  <a className="nav-link">About</a>
                </Link>
              </li>
              <li><a className="mobile-dropdown-anchor" href="http://lineofsight.thelineofsight.com/users/sign_in">Client Login</a></li>
            </ul>
          </div>
        </div>
      </section>
    </nav>
  )
}

export default Nav;
