import Link from "next/link";
import Image from 'next/image';
import React, { useEffect } from "react";

const Footer = () => {
  return (
    <footer className="footer row">
      <div className="col-12">
        <div className="container">
          <div className="row footer-row regular-font">
            <div className="col-12 col-sm-6 footer-col footer-col-2">
              <p>p: 724.778.3391</p>
              <p>e: <a href="mailto:info@thelineofsight.com">info@thelineofsight.com</a></p>
            </div>
            <div className="col-12 col-sm-6 footer-col footer-col-3">
              <div className="row">
                <div className="col-12 text-right footer-icon-container"> 
                  <span className="footer-icon"><a href="https://www.facebook.com/TheLineofSightBook" target="_BLANK"><img src="/img/facebook.png" alt="facebook logo"/></a></span> 
                  <span className="footer-icon"><a href="https://www.youtube.com/channel/UCxtChErhgqUxmPAinG4jCwQ" target="_BLANK"><img src="/img/youtube.png" alt="youtube logo"/></a></span> 
                  <span className="footer-icon"><a href="https://www.instagram.com/the_line_of_sight/" target="_BLANK"><img src="/img/instagram.png" alt="instagram logo"/></a></span> 
                  <span className="footer-icon"><a href="https://twitter.com/LOS_Strategy" target="_BLANK"><img src="/img/twitter.png" alt="twitter logo"/></a></span> 
                  <span className="footer-icon"><a href="https://www.linkedin.com/company/the-line-of-sight" target="_BLANK"><img src="/img/linkedin.png"alt="linkedin logo"/></a></span> 
                </div>
                <div className="col-12 text-right text-container">
                  © Copyright {new Date().getFullYear()} Line of Sight ℠. All rights Reserved. | <Link href="/privacy-policy" legacyBehavior><a className="footer-privacy-link">Privacy Policy</a></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;
